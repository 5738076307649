import * as React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { ArrowList } from "../components/arrow_list/arrowList";
import { Layout } from "../components/Layout";
import ProductBanner from "../components/product_banners/product_banners";
import Banner from "../imgs/banner_ifa_2.jpg";
import IFALogo from "../imgs/logo_ifa.png";
import Propuesta from '../components/propuesta/propuesta';
import './independent-financial-advisor.css';
import IframeVIdeo from "../components/iframe_video/iframe_video";

let text = `La independencia que querés, con el respaldo que necesitás.`;
const IndependentfinancialadvisorPage = () => {
    const pageTitle = 'Sumate al mejor equipo de asesores financieros del mercado';
    const pageDesc = '¿Estás interesado en ser un profesional independiente en el mercado de capitales? Sumate al mejor equipo de asesores financieros del mercado en Balanz.';
    const listProfesional = [
        'Desarrollá tu cartera.',
        'Llevá a cabo tus propias estrategias de inversión.',
        'Manejá tus tiempos.',
        'Tené el respaldo e infraestructura de la empresa líder del mercado, con capacitación constante, research a disposición y atención personalizada.',
        'Contá con la mejor tecnología y plataforma de operaciones.',
    ];
    const page = (
        <>
            <ProductBanner
                bannerContainerClassName="bannerIFA"
                bannerTrasparecy="bannerMask"
                productTitle="Independent Financial Advisor"
                bannerImage={Banner}
                bannerImageClassName="IFABannerImage"
                textContainerClassName="textContainer"
                bannerText={text}
                bannerTextClassName="bannerText2"
                bannerImageMobile={Banner}
                mobile={false}
            />

            <section className="fullwidth page-description pb-0">
                <Container>
                    <Image style={{
                        maxWidth: '412px',
                        width: '100%',
                        marginBottom: '3rem'
                    }} src={IFALogo} />

                    <p className="base-text" >Sumate al mejor equipo de Asesores Financieros del mercado, a través de nuestro programa Independent Financial Advisor.</p>

                    <Row className="pb-5 pt-3">
                        <h2 className="mt-2 pb-2 pb-md-3"><span className="primary-color">¿Estás interesado en ser un profesional independiente en el mercado de capitales?</span></h2>

                        <ArrowList list={listProfesional} />
                    </Row>
                </Container>


                <Propuesta />

                <Container>
                    <Row className="py-5 mb-5 justify-content-center">
                        <Col xs={12}>
                            <h2 className="text-center mb-5 big-title">Convertí tu pasión en tu mejor negocio.</h2>
                        </Col>
                        <Col xs={12} md={10} lg={8} className="d-lg-flex justify-content-lg-center">
                            <IframeVIdeo
                                src="https://www.youtube.com/embed/paIcmBzsB_U"
                                resolutionClass={null}
                            />
                        </Col>
                    </Row>
                </Container>

                <section className="fullwidthbg part-blue lightgrey pb-5 mt-5 info-ifa-section">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={12}  className="column col-text">
                                <div className="section-content text-center">
                                    <h3 className="titulo mx-auto mb-5" >La estructura y soporte de un grande, con la dinámica y flexibilidad de los emprendedores.</h3>
                                    <Row>
                                        <Col xs={12} md={6} lg={6} sm={6}  className="column col-text">
                                            <p className="part-text">Presencia en</p>
                                            <p className="big-text"><span className="highlightsbold">23 provincias.</span></p>
                                        </Col>

                                        <Col xs={12} md={6} lg={6} sm={6}  className="column col-text">
                                            <p className="part-text">Más de</p>
                                            <p className="big-text"><span className="highlightsbold">900.000 clientes.</span></p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

            </section>
        </>
    );

    return <main>
        <Layout title={pageTitle} description={pageDesc} childrem={page} category={"independient-financial-advisor"}></Layout>
    </main>
}
export default IndependentfinancialadvisorPage